<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 400px;"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :setQuickFilter="updateSearchQuery"
      suppressDragLeaveHidesColumns
      @selection-changed="onSelectionChanged"
      suppressMovableColumns
      checkboxSelection="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";
import { toShowAccountingPrice } from "../../util/accountingPrice";

export default {
  emits: ["set-watcher"],
  props: {
    page: { type: Number },
    perPage: { type: Number },
    otherColumn: {
      required: false,
      default: [],
    },
    assetData: [],
    searchText: {
      type: String,
    },
  },
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      isSelectedAsset: false,
    };
  },
  beforeMount() {
    // let columnDefs = [
    //   {
    //     headerName: this.$t("count.order"),
    //     field: "index",
    //     headerCheckboxSelection: true,
    //     headerCheckboxSelectionFilteredOnly: true,
    //     checkboxSelection: true,
    //     maxWidth: 110,
    //     suppressSizeToFit: true,
    //     getQuickFilterText: (params) => {
    //       return "";
    //     },
    //   },
    //   {
    //     headerName: this.$t("maintenance_plan.maintenance_code"),
    //     field: "maintenance_no",
    //     sortable: true,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.fixedas_num"),
    //     field: "id_fixed_asset",
    //     sortable: true,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.as_name"),
    //     field: "name",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.as_dt"),
    //     field: "description",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.cat"),
    //     field: "category",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.sub_cat"),
    //     field: "sub_category",
    //     sortable: true,
    //     minWidth: 200,
    //   },
    //   {
    //     headerName: this.$t("home.build"),
    //     field: "location_building",
    //     sortable: true,
    //     maxWidth: 130,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.fl"),
    //     field: "location_floor",
    //     sortable: true,
    //     maxWidth: 110,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.department"),
    //     field: "location_department",
    //     sortable: true,
    //     maxWidth: 130,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.zone"),
    //     field: "location_zone",
    //     sortable: true,
    //     maxWidth: 130,
    //     suppressSizeToFit: true,
    //   },
    //   {
    //     headerName: this.$t("home.note"),
    //     field: "note",
    //     sortable: true,
    //     minWidth: 150,
    //   },
    //   {
    //     headerName: "QR Code",
    //     field: "id_qrcode",
    //     sortable: true,
    //     suppressSizeToFit: true,
    //     cellStyle: (params) => {
    //       if (params.value === this.$t("home.not_reg")) {
    //         return { color: "#888888" };
    //       }
    //       return null;
    //     },
    //   },
    // ];
    // this.columnDefs = columnDefs;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
    }),
    rowData() {
      return this.assetData.map((asset, index) => {
        let dataTable = {
          index: (this.page - 1) * this.perPage + index + 1,
          asset_repair_report_id: asset.asset_repair_report_id,
          maintenance_no: asset.fixed_asset_repair_report_id,
          maintenance_date: asset.repair_date
            ? this.momentDay(asset.repair_date)
            : "-",
          maintenance_type: asset.repair_type || "-",
          maintenance_detail: asset.detail || "-",
          maintenance_price: asset.repair_cost
            ? toShowAccountingPrice(asset.repair_cost)
            : "-",
          ref_1: asset.ref1 || "-",
          ref_2: asset.ref2 || "-",
          vendor_in_charge: asset.vendor_name || "-",
          id_fixed_asset: asset.asset.id_fixed_asset,
          name: asset.asset.name,
          description: asset.asset.description || "-",
          category: asset.asset.categories?.name || "-",
          sub_category: asset.asset.sub_category?.name || "-",
          sector: asset.asset.sector?.name || "-",
          branch: asset.asset.branch?.name || "-",
          location_building: asset.asset.location_buildings?.name || "-",
          location_floor: asset.asset.location_floors?.name || "-",
          location_department: asset.asset.location_departments?.name || "-",
          location_zone: asset.asset.location_zones?.name || "-",
          note: asset.asset.note || "-",
          id_qrcode: asset.asset.qrcode?.id_qrcode || this.$t("home.not_reg"),
          is_cancel: asset.is_cancel,
          purchase_date: asset.asset.purchase_date
            ? this.momentDay(asset.asset.purchase_date)
            : "-",
          purchase_price: asset.asset.purchase_price
            ? toShowAccountingPrice(asset.asset.purchase_price)
            : "-",
          useful_life: asset.asset.useful_life || "-",
          start_date: asset.asset.start_date
            ? this.momentDay(asset.asset.start_date)
            : "-",
          acc_depreciation: asset.asset.acc_depreciation || "-",
          net_book: asset.asset.net_book || "-",
          insurance_start: asset.asset.custom_start_time
            ? this.momentDay(asset.asset.custom_start_time)
            : "-",
          insurance_end: asset.asset.custom_end_time
            ? this.momentDay(asset.asset.custom_end_time)
            : "-",
          vendor: asset.asset.vendor?.name || "-",
          cancel_date: asset.cancel_date
            ? this.momentDay(asset.cancel_date)
            : "-",
        };
        if (asset.asset.asset_details.length > 0) {
          asset.asset.asset_details.forEach((val, i) => {
            dataTable[`other_${i}`] = val.value || "-";
          });
        } else {
          //replace data null
          for (let i = 0; i < this.otherColumn.length; i++) {
            dataTable[`other_${i}`] = "-";
          }
        }
        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
  },
  watch: {
    assetData: {
      handler() {
        this.isSelectedAsset = false;
      },
      deep: true,
    },
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    otherColumn: {
      handler() {
        this.loopHeaderFreeColumn();
      },
      deep: true,
    },
  },
  methods: {
    loopHeaderFreeColumn() {
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          maxWidth: 110,
          suppressSizeToFit: true,

          getQuickFilterText: (params) => {
            return "";
          },
        },
        {
          headerName: this.$t("maintenance_plan.maintenance_code"),
          field: "maintenance_no",
          sortable: true,
          suppressSizeToFit: true,
          cellStyle: (params) => {
            if (params.data.is_cancel) {
              return { color: " #ED1616" };
            }
          },
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.as_name"),
          field: "name",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.as_dt"),
          field: "description",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.cat"),
          field: "category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.sub_cat"),
          field: "sub_category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.build"),
          field: "location_building",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.fl"),
          field: "location_floor",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.department"),
          field: "location_department",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.zone"),
          field: "location_zone",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.note"),
          field: "note",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "QR Code",
          field: "id_qrcode",
          sortable: true,
          suppressSizeToFit: true,
          cellStyle: (params) => {
            if (params.value === this.$t("home.not_reg")) {
              return { color: "#888888" };
            }
            return null;
          },
        },
      ];
      if (!this.isRoleLower) {
        const customColumnJGT = [
          {
            position: 2,
            value: {
              headerName: this.$t("maintenance_plan.maintenance_date"),
              field: "maintenance_date",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 3,
            value: {
              headerName: this.$t("maintenance_plan.maintenance_type"),
              field: "maintenance_type",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 4,
            value: {
              headerName: this.$t("maintenance_plan.maintenance_detail"),
              field: "maintenance_detail",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 5,
            value: {
              headerName: this.$t("maintenance_plan.maintenance_price"),
              field: "maintenance_price",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 6,
            value: {
              headerName: this.$t("maintenance_plan.ref_1"),
              field: "ref_1",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 7,
            value: {
              headerName: this.$t("maintenance_plan.ref_2"),
              field: "ref_2",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 8,
            value: {
              headerName: this.$t("maintenance_plan.vendor_in_charge"),
              field: "vendor_in_charge",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 14,
            value: {
              headerName: this.$t("asset.location"),
              field: "sector",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 15,
            value: {
              headerName: this.$t("asset.branch"),
              field: "branch",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 28,
            value: {
              headerName: this.$t("asset.insurance_start"),
              field: "insurance_start",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 29,
            value: {
              headerName: this.$t("asset.insurance_end"),
              field: "insurance_end",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 30,
            value: {
              headerName: this.$t("asset.vendor"),
              field: "vendor",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 100,
            value: {
              headerName: this.$t("maintenance_plan.cancel_date"),
              field: "cancel_date",
              sortable: true,
              suppressSizeToFit: true,
              cellStyle: (params) => {
                if (params.value !== "-") {
                  return { color: " #ED1616" };
                }
              },
            },
          },
        ];
        let fix_column = [
          {
            headerName: this.$t("asset.purchase_date"),
            field: "purchase_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.useful_life"),
            field: "useful_life",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.start_date"),
            field: "start_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.acc_depreciation"),
            field: "acc_depreciation",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.net_book"),
            field: "net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
        ];
        columnDefs = columnDefs.concat(fix_column);
        if (this.assetData.length > 0) {
          let column = this.otherColumn;
          column.forEach((val, i) => {
            let header = {
              headerName: column[i].name,
              field: `other_${i}`,
              suppressSizeToFit: true,
            };
            columnDefs.push(header);
          });
        }
        if (this.permissionStatus.company.custom_id === 2) {
          for (const item of customColumnJGT) {
            columnDefs.splice(item.position, 0, item.value);
          }
        }
      }
      this.columnDefs = columnDefs;
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
      this.$emit("set-watcher");
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      this.isSelectedAsset = selectedNodes.length > 0;
      const selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      this.$emit("selected-assets", selectedData);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>

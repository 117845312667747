<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto" style="font-size: 18px">
        {{ $t("asset.choose_ex") }}
      </p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me btn-auto" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <b-modal
      v-if="selectedMenu === 'summary'"
      id="filter-modal"
      @cancel="handleclear"
      :cancel-title="$t('btn.clear')"
      hide-header-close
      hide-header
      :ok-title="$t('btn.conf')"
      @ok="handleFilter"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>
        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <label>{{ $t("maintenance_plan.maintenance_range") }}</label>
      <div class="row align-items-center">
        <date-picker
          class="inline-block h-full col-6"
          v-model="filterOptions.start_date"
          locale="en"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="row">
              <div class="col">
                <input
                  style="cursor: pointer;"
                  :value="inputValue | formatDate"
                  class="form-control px-20"
                  readonly
                  @click="togglePopover()"
                />
              </div>
            </div>
          </template>
        </date-picker>
        -
        <date-picker
          class="inline-block h-full col"
          v-model="filterOptions.end_date"
          :min-date="filterOptions.start_date"
          locale="en"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="row">
              <div class="col-12">
                <input
                  style="cursor: pointer;"
                  :value="inputValue | formatDate"
                  class="form-control px-20"
                  readonly
                  @click="togglePopover()"
                />
              </div>
            </div>
          </template>
        </date-picker>
      </div>
      <label class="mt-2">{{ $t("maintenance_plan.vendor_in_charge") }}</label>
      <v-select
        :options="optionSummaryVendor"
        v-model="filterOptions.vendor"
        :reduce="(option) => option.value"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("asset.region_province") }}</label>
      <v-select
        :options="optionSector"
        v-model="filterOptions.sector"
        :reduce="(option) => option.value"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("asset.branch") }}</label>
      <v-select
        :options="optionBranch"
        v-model="filterOptions.branch"
        :reduce="(option) => option.value"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("filter.cat") }}</label>
      <v-select
        :options="optionCategory"
        :reduce="(option) => option.value"
        v-model="filterOptions.category"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("filter.sub_cat") }}</label>
      <v-select
        :options="optionSubCategory"
        v-model="filterOptions.sub_category"
        :reduce="(option) => option.value"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("maintenance_plan.maintenance_type") }}</label>
      <select
        class="form-control form-border"
        v-model="filterOptions.maintenance_type"
      >
        <option :value="null">ทั้งหมด</option>
        <option value="MA">MA</option>
        <option value="Repair">Repair</option>
        <option value="Other">Other</option>
      </select>
    </b-modal>
    <b-modal
      v-if="selectedMenu === 'summary'"
      id="cancel-modal"
      :cancel-title="$t('btn.canc')"
      centered
      hide-header-close
      hide-header
      hide-footer
      :ok-title="$t('btn.conf')"
    >
      <h5 class="text-center mb-3">{{ $t("dialog.conf_canc_maintenance") }}</h5>
      <div class="d-flex justify-content-center w-100">
        <button class="btn cancel" @click="$bvModal.hide('cancel-modal')">
          {{ $t("btn.canc") }}
        </button>
        <button class="btn confirm-button" @click="cancelMaintenance">
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>
    <b-modal
      id="delete-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <h5 class="text-center my-3">{{ $t("dialog.del_plan") }}</h5>
      <div class="mx-auto my-3" style="width: fit-content;">
        <button
          type="button"
          class="btn cancel"
          @click="$bvModal.hide('delete-modal')"
        >
          {{ $t("btn.canc") }}
        </button>
        <button type="submit" class="btn" @click="deletePlan">
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>
    <div class="responsive-bar">
      <Footer></Footer>
      <div>
        <div class="back-icon">
          <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
        </div>
        <h1 class="header-text">
          <div class="topic">{{ headerText }}</div>
        </h1>
        <div class="py-3">
          <div
            v-if="selectedMenu !== 'plantable'"
            class="maintenance-menu my-3"
          >
            <button
              class="btn m-2"
              :class="{ 'outline-button': selectedMenu !== 'plan' }"
              @click="selectMenu('plan')"
            >
              {{ $t("maintenance_plan.plan") }}
            </button>
            <button
              class="btn m-2"
              :class="{ 'outline-button': selectedMenu !== 'list' }"
              @click="selectMenu('list')"
            >
              {{ $t("maintenance_plan.list") }}
            </button>
            <button
              class="btn m-2"
              :class="{ 'outline-button': selectedMenu !== 'summary' }"
              @click="selectMenu('summary')"
            >
              {{ $t("maintenance_plan.summary") }}
            </button>
          </div>
          <div v-if="selectedMenu === 'plan'" class="container">
            <div class="mx-auto" style="max-width: 500px;">
              <div class="plan-toggle">
                <div
                  class="d-flex align-items-center"
                  @click="selectMenu('plan')"
                >
                  <b-icon-list
                    style="width: 25px; height: 25px;"
                    :style="{
                      color: selectedMenu === 'plan' ? '#007afe' : '#7c7c7c',
                    }"
                  />
                </div>
                <div
                  class="d-flex align-items-center"
                  @click="selectMenu('plantable')"
                >
                  <b-icon-table
                    style="width: 25px; height: 25px;"
                    :style="{
                      color:
                        selectedMenu === 'plantable' ? '#007afe' : '#7c7c7c',
                    }"
                  />
                </div>
              </div>
              <div v-if="planData.length > 0">
                <div
                  v-for="plan in planData"
                  class="plan-card text-start"
                  :key="plan.preventive_ma_plan_id"
                >
                  <div @click="gotoPlanList(plan.preventive_ma_plan_id)">
                    <img
                      src="../../assets/calendar-gradient-event.svg"
                      class="mx-2"
                      alt=""
                    />
                  </div>
                  <div
                    class="w-100"
                    @click="gotoPlanList(plan.preventive_ma_plan_id)"
                  >
                    <p>
                      <b> {{ plan.name }}</b>
                    </p>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.start") }}</p>
                      </div>
                      <div class="col-8">
                        <p>
                          {{
                            $t("maintenance_plan.date_time", {
                              date: moment(plan.start_date).format(
                                "DD/MM/YYYY"
                              ),
                              time: moment(plan.start_date).format("HH:mm"),
                            })
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.loop") }}</p>
                      </div>
                      <div class="col-8">
                        <p>
                          {{
                            $t("maintenance_plan.every_month", {
                              value: plan.range_of_recurring_month,
                            })
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.end") }}</p>
                      </div>
                      <div class="col-8">
                        <p>{{ moment(plan.end_date).format("DD/MM/YYYY") }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.vendor") }}</p>
                      </div>
                      <div class="col-8">
                        <p>{{ plan.vendor.name }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.asset_amount") }}</p>
                      </div>
                      <div class="col-8">
                        <p>
                          {{ plan.preventive_ma_plan_assets.length }}
                          {{ $t("maintenance_plan.asset") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style="width: fit-content; cursor: pointer;"
                    class="d-flex align-items-start"
                  >
                    <b-dropdown
                      id="edit-menu"
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      dropright
                    >
                      <template #button-content>
                        <img src="../../assets/more-detail.svg" alt="" />
                      </template>
                      <b-dropdown-item
                        @click="
                          $router.push({
                            name: 'EditMaintenancePlan',
                            params: {
                              planId: plan.preventive_ma_plan_id,
                            },
                          })
                        "
                        ><div class="d-flex align-items-center">
                          <img
                            class="me-2"
                            src="../../assets/edit_pencil.svg"
                            alt=""
                          />
                          <p class="menu-item">
                            {{ $t("btn.edit") }}
                          </p>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="setDeletePlan(plan.preventive_ma_plan_id)"
                        ><div class="d-flex align-items-center">
                          <img
                            class="me-2"
                            src="../../assets/delete_bin.svg"
                            alt=""
                          />
                          <p class="menu-item" style="color: #ED1616;">
                            {{ $t("btn.del_all") }}
                          </p>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <div v-else class="plan-card align-items-center">
                <div class="mx-auto">
                  <b-icon-calendar4 scale="1.5" />
                  <p>{{ $t("maintenance_plan.no_plan") }}</p>
                </div>
              </div>
              <button class="outline-button" @click="createPlan">
                + {{ $t("maintenance_plan.add_plan") }}
              </button>
            </div>
          </div>
          <div v-if="selectedMenu === 'plantable'" class="mx-auto">
            <div class="container">
              <div class="gradient-text my-4">
                <h2>
                  {{
                    $t("maintenance_plan.year_summary_plan", {
                      year: moment(new Date()).format("YYYY"),
                    })
                  }}
                </h2>
              </div>
              <div class="plan-toggle">
                <div
                  class="d-flex align-items-center"
                  @click="selectMenu('plan')"
                >
                  <b-icon-list
                    style="width: 25px; height: 25px;"
                    :style="{
                      color: selectedMenu === 'plan' ? '#007afe' : '#7c7c7c',
                    }"
                  />
                </div>
                <div
                  class="d-flex align-items-center"
                  @click="selectMenu('plantable')"
                >
                  <b-icon-table
                    style="width: 25px; height: 25px;"
                    :style="{
                      color:
                        selectedMenu === 'plantable' ? '#007afe' : '#7c7c7c',
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="container-fluid my-3">
              <div class="plan-table-container">
                <div class="plan-table header">
                  <div>
                    <p>{{ moment(new Date()).format("YYYY") }}</p>
                  </div>
                  <div><p>Jan</p></div>
                  <div><p>Feb</p></div>
                  <div><p>Mar</p></div>
                  <div><p>Apr</p></div>
                  <div><p>May</p></div>
                  <div><p>Jun</p></div>
                  <div><p>Jul</p></div>
                  <div><p>Aug</p></div>
                  <div><p>Sep</p></div>
                  <div><p>Oct</p></div>
                  <div><p>Nov</p></div>
                  <div><p>Dec</p></div>
                </div>
                <div
                  v-for="vendor in plantableData"
                  :key="vendor.vendor_id"
                  class="plan-table item"
                >
                  <div style="font-weight: 700;">
                    <p>{{ vendor.name }}</p>
                  </div>
                  <div
                    v-for="i in 12"
                    :key="i"
                    :style="
                      vendor.tableData[i]
                        ? 'background: #2F6ED4; color: white;'
                        : ''
                    "
                  >
                    <p>
                      {{
                        vendor.tableData[i] ? vendor.tableData[i].length : ""
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedMenu === 'list'" class="container">
            <div class="mx-auto" style="max-width: 500px;">
              <p class="text-start">
                <b>{{ $t("maintenance_plan.this_month_plan") }}</b>
              </p>
              <div v-if="thisMonthRoundList.length > 0">
                <div
                  class="plan-card text-start"
                  @click="
                    gotoPlanListDetail({
                      id: round.preventive_ma_plan_id,
                      date: round.round_date,
                    })
                  "
                  v-for="round in thisMonthRoundList"
                  :key="round.preventive_ma_plan_id"
                >
                  <div>
                    <img
                      src="../../assets/calendar-gradient-event.svg"
                      class="mx-2"
                      alt=""
                    />
                  </div>
                  <div class="w-100">
                    <p>
                      <b>{{ round.name }}</b>
                    </p>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.start") }}</p>
                      </div>
                      <div class="col-8">
                        <p>
                          {{
                            $t("maintenance_plan.date_time", {
                              date: moment(round.round_date).format(
                                "DD/MM/YYYY"
                              ),
                              time: moment(round.round_date).format("HH:mm"),
                            })
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.vendor") }}</p>
                      </div>
                      <div class="col-8">
                        <p>{{ round.vendor.name }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.asset_amount") }}</p>
                      </div>
                      <div class="col-8">
                        <p>
                          {{ round.preventive_ma_plan_assets.length }}
                          {{ $t("maintenance_plan.asset") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="plan-card align-items-center" v-else>
                <div class="mx-auto">
                  <b-icon-calendar4 scale="1.5" />
                  <p>{{ $t("maintenance_plan.no_plan") }}</p>
                </div>
              </div>
              <hr style="border-bottom: 1px solid #7c7c7c" />
              <p class="text-start">
                <b>{{
                  $t("maintenance_plan.next_month_plan", {
                    value: 3,
                    unit: "เดือน",
                  })
                }}</b>
              </p>
              <div v-if="nextMountRoundList.length > 0">
                <div
                  class="plan-card text-start"
                  @click="
                    gotoPlanListDetail({
                      id: round.preventive_ma_plan_id,
                      date: round.round_date,
                    })
                  "
                  v-for="round in nextMountRoundList"
                  :key="round.preventive_ma_plan_id"
                >
                  <div>
                    <img
                      src="../../assets/calendar-gradient-event.svg"
                      class="mx-2"
                      alt=""
                    />
                  </div>
                  <div class="w-100">
                    <p>
                      <b>{{ round.name }}</b>
                    </p>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.start") }}</p>
                      </div>
                      <div class="col-8">
                        <p>
                          {{
                            $t("maintenance_plan.date_time", {
                              date: moment(round.round_date).format(
                                "DD/MM/YYYY"
                              ),
                              time: moment(round.round_date).format("HH:mm"),
                            })
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.vendor") }}</p>
                      </div>
                      <div class="col-8">
                        <p>{{ round.vendor.name }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>{{ $t("maintenance_plan.asset_amount") }}</p>
                      </div>
                      <div class="col-8">
                        <p>
                          {{ round.preventive_ma_plan_assets.length }}
                          {{ $t("maintenance_plan.asset") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="plan-card align-items-center" v-else>
                <div class="mx-auto">
                  <b-icon-calendar4 scale="1.5" />
                  <p>{{ $t("maintenance_plan.no_plan") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedMenu === 'summary'" class="container-fluid">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center flex-wrap">
                <button
                  for="fusk"
                  type="submit"
                  class="mx-1 outline-button table"
                  @click="$refs.fileInput.click()"
                >
                  <div
                    style="display: flex; align-items: center; justify-content: space-evenly;"
                  >
                    <b-icon-download
                      font-scale="1.5"
                      style="margin-right: 5px"
                    />
                    {{ $t("maintenance_plan.save_maintenance_result") }}
                  </div>
                </button>
                <input
                  id="fusk"
                  type="file"
                  ref="fileInput"
                  style="display: none; margin: 0px !important font-size: 10px;"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  @change="onFileChange($event)"
                />
                <button
                  v-if="!isNativeApp"
                  @click="$bvModal.show('export-modal')"
                  class="mx-1 outline-button table"
                >
                  <div
                    style="
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
                  >
                    <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
                    {{ $t("btn.ex_file") }}
                  </div>
                </button>
                <transition name="fade">
                  <button
                    v-if="allowCancel"
                    class="cancel-maintenance"
                    @click="$bvModal.show('cancel-modal')"
                  >
                    <b-icon-trash
                      class="-mt-5"
                      style="width: 20px; margin-top: -3px"
                    />
                    {{ $t("btn.canc") }}
                  </button>
                </transition>
              </div>
              <div class="d-flex">
                <span
                  class="filter-box"
                  style="
                border-radius: 10px;
                display: flex;
                padding-left: 1rem;
                margin-right: 1rem;
              "
                >
                  <img src="@/assets/search-white.svg" width="20px" />
                  <input
                    type="text"
                    class="form-control filter-box"
                    style="
                  height: 45px;
                  width: 100%;
                  border-radius: 10px 10px 10px 10px;
                "
                    v-debounce:700ms="searchByText"
                    :placeholder="$t('home.search')"
                    v-model="searchText"
                  />
                </span>

                <!-- Filter Button -->
                <button
                  class="filter"
                  style="border: none"
                  type="button"
                  id="button-addon2"
                  @click="$bvModal.show('filter-modal')"
                >
                  <img src="@/assets/filter-white.svg" />
                </button>
              </div>
            </div>
            <MaintenancePlanTable
              :assetData="summaryData"
              :otherColumn="otherColumn"
              :page="currentPage"
              :perPage="perPage"
              class="my-4"
              @set-watcher="setWatcher"
              ref="SummaryTable"
            />
            <div
              class="d-flex justify-content-center mt-3"
              v-if="summaryData.length > 0"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
import MaintenancePlanTable from "@/components/CustomTable/MaintenancePlanSummaryTable.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import { mapGetters } from "vuex";
import axios from "axios";
import baseUrl from "../../util/backend";
import { authHeader } from "../../store/actions";

export default {
  components: { Footer, MaintenancePlanTable, DatePicker, Loading },
  data() {
    return {
      isLoading: false,
      id_company: null,
      selectedMenu: "plan",
      searchText: "",
      currentPage: 1,
      perPage: 100,
      totalRows: 1,
      filterOptions: {
        start_date: null,
        end_date: null,
        vendor: null,
        sector: null,
        branch: null,
        category: null,
        sub_category: null,
        maintenance_type: null,
      },
      planData: [],
      thisMonthRoundList: [],
      nextMountRoundList: [],
      planToDelete: null,
      summaryData: [],
      summaryVendorList: [],
      otherColumn: [],
      allowCancel: false,
      plantableData: {},
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      permissionStatus: "permissionStatus",
      optionChoice: "getfilterOption",
      getUserDetail: "getUserDetail",
    }),
    headerText() {
      if (this.selectedMenu === "plan") {
        return this.$t("maintenance_plan.maintenance_plan");
      }
      if (this.selectedMenu === "list") {
        return this.$t("maintenance_plan.list");
      }
      if (this.selectedMenu === "summary") {
        return this.$t("maintenance_plan.summary");
      }
      return "";
    },
    optionSummaryVendor() {
      const opt =
        this.summaryVendorList?.map((v) => {
          return {
            label: v,
            value: v,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionSector() {
      const opt =
        this.optionChoice.optionSector?.map((sector) => {
          return {
            label: sector.name,
            value: sector.sector_id,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionBranch() {
      const branch = this.optionChoice.optionBranch?.filter(
        (opt) => opt.sector_id === this.filterOptions.sector
      );
      const opt =
        branch?.map((b) => {
          return {
            label: b.name,
            value: b.branch_id,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionCategory() {
      const opt =
        this.optionChoice.optionCategory?.map((cat) => {
          return {
            label: cat.name,
            value: cat.id_category,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionSubCategory() {
      const category = this.optionChoice.optionCategory?.find(
        (opt) => opt.id_category === this.filterOptions.category
      );
      const opt =
        category?.sub_categories?.map((c) => {
          return {
            label: c.name,
            value: c.id_sub_category,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
  },
  watch: {
    "$route.query.menu"(_, oldValue) {
      if (oldValue) {
        this.selectedMenu = this.$route.query.menu || "plan";
        this.loadPageData();
      }
    },
    currentPage() {
      this.loadMaintenanceSummary();
    },
    "filterOptions.sector"() {
      this.filterOptions.branch = null;
    },
    "filterOptions.category"() {
      this.filterOptions.sub_category = null;
    },
    "filterOptions.start_date"() {
      if (this.filterOptions.start_date > this.filterOptions.end_date) {
        this.filterOptions.end_date = this.filterOptions.start_date;
      }
    },
  },
  methods: {
    moment,
    async handleclear() {
      // this.countingStatus = "ทั้งหมด";
      this.currentPage = 1;
      this.searchText = "";
      this.filterOptions = {
        start_date: null,
        end_date: null,
        vendor: null,
        sector: null,
        branch: null,
        category: null,
        sub_category: null,
        maintenance_type: null,
      };
      this.loadPageData();
    },

    handleFilter() {
      this.loadPageData();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      const fd = new FormData();
      // fd.append("id_user", id_user); // sent name who upload asset
      fd.append("file", file);
      this.$refs.fileInput.value = null;
      this.isLoading = true;
      try {
        const res = await axios.post(
          `${baseUrl()}maPlan/importRepairReport/${this.id_company}`,
          fd,
          authHeader()
        );
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      await this.loadPageData();
      this.isLoading = false;
    },
    setWatcher() {
      this.$watch(
        () => {
          return this.$refs.SummaryTable.isSelectedAsset;
        },
        (val) => {
          this.allowCancel = val;
        }
      );
    },
    setDeletePlan(id) {
      this.planToDelete = id;
      this.$bvModal.show("delete-modal");
    },
    async deletePlan() {
      this.isLoading = true;
      try {
        await axios.delete(`${baseUrl()}maPlan/deleteMaPlan`, {
          ...authHeader(),
          params: {
            preventive_ma_plan_id: this.planToDelete,
            id_company: this.id_company,
          },
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.$bvModal.hide("delete-modal");
      this.isLoading = false;
      this.loadPageData();
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    gotoHome() {
      this.$router.push("/");
    },
    async cancelMaintenance() {
      const row = this.$refs.SummaryTable.getSelectedRowData();
      console.log(row);
      const cancelArr = row.map((asset) => asset.asset_repair_report_id);
      try {
        await this.$store.dispatch("cancelRepairReport", {
          id_company: this.id_company,
          asset_repair_report_id_list: cancelArr,
        });
      } catch (err) {
        alert(err);
      }
      this.$bvModal.hide("cancel-modal");
      this.allowCancel = false;
      this.loadPageData();
    },
    gotoPlanList(id) {
      this.$router.push({
        name: "MaintenancePlanList",
        params: {
          planId: id,
        },
      });
    },
    gotoPlanListDetail(data) {
      this.$router.push({
        name: "MaintenancePlanDetail",
        params: {
          page: "list",
          id: data.id,
        },
        query: {
          date: data.date,
        },
      });
    },
    selectMenu(menu) {
      this.selectedMenu = menu;
      this.$router.push({ path: this.$route.path, query: { menu: menu } });
    },
    async searchByText() {
      this.loadPageData();
    },
    createPlan() {
      this.$store.dispatch("setMaintenancePlanAsset", []);
      this.$router.push({ name: "CreateMaintenancePlan" });
    },
    async onExport(isMail) {
      this.isLoading = true;
      const row = this.$refs.SummaryTable.getSelectedRowData();
      const selectedArr = row.map((asset) => asset.asset_repair_report_id);
      await axios
        .post(
          `${baseUrl()}maPlan/exportReportRepair/${this.id_company}`,
          {
            id_user: this.getUserDetail.id_user,
            report_list: selectedArr.length > 0 ? selectedArr : null,
          },
          { ...authHeader(), responseType: "blob" }
        )
        .then((response) => {
          if (!isMail) {
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            const elink = document.createElement("a");
            elink.download = this.$t("maintenance_plan.summary") + ".xlsx";
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
      this.$bvModal.hide("export-modal");
    },
    async loadPageData() {
      this.isLoading = true;
      console.log(`%c${this.selectedMenu}`, "font-size:40px; color: aqua;");
      if (this.selectedMenu === "plan") {
        try {
          const res = await axios.get(
            `${baseUrl()}maPlan/getAllMaPlans?id_company=${this.id_company}`,
            authHeader()
          );
          this.planData = res.data.data;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.message);
          }
        }
      }
      if (this.selectedMenu === "list") {
        try {
          const roundList = await this.$store.dispatch("loadAllPlanRoundList", {
            id_company: this.id_company,
          });
          console.log(roundList);
          const current = roundList.filter((round) =>
            moment(round.round_date).isSame(new Date(), "M")
          );
          const next = roundList.filter((round) =>
            moment(round.round_date).isSame(moment(new Date()).add(1, "M"), "M")
          );
          this.thisMonthRoundList = current;
          this.nextMountRoundList = next;
        } catch (err) {
          alert(err);
        }
      }
      if (this.selectedMenu === "plantable") {
        try {
          const vendor = await axios.get(
            `${baseUrl()}maPlan/getAllVendor?id_company=${this.id_company}`,
            authHeader()
          );
          const vendorList = vendor.data.data;
          const roundList = await this.$store.dispatch("loadAllPlanRoundList", {
            id_company: this.id_company,
          });
          const currentYearRound = roundList.filter((round) =>
            moment(round.round_date).isSame(new Date(), "Y")
          );
          let tableData = {};
          for (let i = 0; i < currentYearRound.length; i++) {
            let vendortable = currentYearRound[i].vendor_id;
            let monthtable = moment(currentYearRound[i].round_date).format("M");
            if (!tableData[vendortable]) tableData[vendortable] = {};
            if (tableData[vendortable][monthtable]) {
              tableData[vendortable][monthtable].push(currentYearRound[i]);
            } else {
              tableData[vendortable][monthtable] = [currentYearRound[i]];
            }
          }
          this.plantableData = vendorList.map((vendor) => {
            return {
              ...vendor,
              tableData: tableData[vendor.vendor_id]
                ? tableData[vendor.vendor_id]
                : [],
            };
          });
        } catch (err) {
          alert(err);
        }
      }
      if (this.selectedMenu === "summary") {
        this.$store.dispatch("loadOptionChoice");
        await this.loadMaintenanceSummary();
      }
      this.isLoading = false;
    },

    async loadMaintenanceSummary() {
      this.isLoading = true;
      const res = await axios.get(`${baseUrl()}maPlan/getAllRepairReport`, {
        params: {
          id_company: this.id_company,
          page: this.currentPage,
          limit: this.perPage,
          text: this.searchText || null,
          repair_start_time:
            this.filterOptions.start_date &&
            moment(this.filterOptions.start_date).format("YYYY-MM-DD"),
          repair_end_time:
            this.filterOptions.end_date &&
            moment(this.filterOptions.end_date).format("YYYY-MM-DD"),
          vendor_name: this.filterOptions.vendor,
          sector_id: this.filterOptions.sector,
          branch_id: this.filterOptions.branch,
          category_id: this.filterOptions.category,
          sub_category_id: this.filterOptions.sub_category,
          repair_type: this.filterOptions.maintenance_type,
        },
        ...authHeader(),
      });
      this.summaryData = res.data.data;
      this.otherColumn = res.data.other_column;
      this.totalRows = res.data.data.count;
      this.summaryVendorList = res.data.optionsVendor;
      this.isLoading = false;
    },
  },

  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    const menu = this.$route.query.menu || "plan";
    this.selectedMenu = menu;
    if (!this.$route.query.menu) {
      this.$router.replace({
        path: this.$route.path,
        query: { menu: menu },
      });
    }
    this.loadPageData();
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 14px;
}

.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 28px;
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 0px;
  }
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

.fill-button {
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}
.outline-button {
  width: 160px;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  &.table {
    font-size: 14px;
    height: 100%;
    width: auto;
    margin: 0;
    padding: 5px 10px;
  }
}

.plan-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div {
    padding: 5px 15px;
    &:first-child {
      border: 1px solid #7c7c7c;
      border-radius: 5px 0 0 5px;
      border-right: 0px;
    }
    &:last-child {
      border: 1px solid #7c7c7c;
      border-radius: 0 5px 5px 0;
    }
  }
}

.plan-card {
  display: flex;
  cursor: pointer;
  min-height: 150px;
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  .row {
    p {
      color: #7c7c7c;
    }
  }
}

.plan-table-container {
  width: 100%;
  overflow-x: scroll;
  border: 1px solid #7c7c7c;
}
.plan-table {
  p {
    margin: 0;
  }
  display: grid;
  grid-template-columns: repeat(13, 120px);
  width: min-content;
  &.header {
    background: linear-gradient(180deg, #007afe 0%, #014287 100%);
    color: #fff;
    padding: 10px 0;
  }
  &.item {
    height: 120px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #7c7c7c;
    }
  }
}

.maintenance-menu {
  .btn {
    height: auto;
    padding: 5px;
  }
}

#edit-menu {
  &:focus {
    outline: none;
  }

  ::v-deep button {
    *:focus {
      outline: none;
    }
  }
  ::v-deep .btn {
    background: none;
    width: fit-content;
    display: block;
    padding: 0;
    min-width: 0px;
  }
  ::v-deep .dropdown-toggle::after {
    display: none;
  }
  ::v-deep .dropdown-item:active {
    background-color: #e9ecef;
  }

  img {
    width: 15px;
  }

  .menu-item {
    margin: 0;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.download-file {
  padding: 0 10px 0 10px;
  color: white;
  border-radius: 8px;
  border: none;
  width: 130px;
  height: 34px;
  font-size: 14px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box;
}
.email-me {
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 8px;
  width: 130px;
  height: 34px;
  font-size: 14px;
  margin-right: 10px;
}

.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

.cancel {
  margin-right: 10px;
  // margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.cancel-maintenance {
  margin-right: 1rem;
  // transition: 2s;
  color: red;
  background: transparent;
  border: 2px solid red;
  border-radius: 8px !important;
  padding: 0.5rem;
}
</style>
